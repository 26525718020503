import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionLayout from "../components/SectionLayout"
import Icon from '@mdi/react'
import { mdiOpenInNew } from '@mdi/js';

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo title="404: Nie znaleziono" tags={[]}/>
          <SectionLayout sectionname="Nie znaleziono" notFlex={true}>
            <p>Niestety, strona pod tym adresem nie istnieje.</p>
            <p>Zapraszam do przejścia na <Link className="link-404 items-center underline text-teal-600 hover:text-teal-300" to="/">
            stronę główną<Icon className="ml-1 align-text-bottom inline-block" path={mdiOpenInNew} size="1rem" />
            </Link>.
            </p>
          </SectionLayout>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
